<template>
  <!-- TODO: Add a real loading state -->
  <span>Loading...</span>
</template>

<script>
import GET_USER_QUERY from '@/graphql/users/GetCurrentUserQuery.graphql'
import { logError } from '@/utils/logging'

/*
 * This is a simplified version of how the "/" route will push the user to the appropriate module.
 *
 * TODO: Is this better as a Navigation Guard?
 *       Unsure of how to get user info in guard but might be a better approach.
 *
 * TODO: Only use defaultModule if no module is present already.
 *       Might need to add module state back into Vuex for this feature.
 */
export default {
  data() {
    return {}
  },
  apollo: {
    user: {
      query: GET_USER_QUERY,
      fetchPolicy: 'network-only',
      update(data) {
        const defaultModule = data.getUser.edcRoles && data.getUser.edcRoles.length ? 'edc' : 'ctms'
        this.$router.push(`/${defaultModule}`)
      },
      error (error) {
        logError(error, 'Index.vue user query')
      }
    }
  }
}
</script>
